import React from 'react';
import { MainLayout } from '../layouts';
import {
  LegalContainer,
  LegalContainerLight
} from '../components/pages/shared/LegalStyles';

import { Button } from '../components';

export default () => (
  <MainLayout>
    <LegalContainer>
      <div>
        <h1 id="logoguidelines">Logo Guidelines</h1>
        <p>
          <em>Effective Date: November 24, 2019</em>
        </p>
        <p>
          The AirPage brand includes the words, phrases, symbols, designs and
          other distinctive brand features associated with AirPage and our
          services (“Brand Assets”).
        </p>
        <h3 id="requirements">Requirements</h3>
        <p>
          Before using an AirPage logo, please be sure to follow our basic
          rules:
        </p>
        <ol>
          <li>
            <p>
              Comply with our <a href="/brand-guidelines">Brand Guidelines</a>&nbsp;and <a href="/corporate-colors">Corporate Colors</a>.
            </p>
          </li>
          <li>
            <p>
              Don’t alter the shape, proportion, color or orientation of the
              logos. Keep them only as they appear below.
            </p>
          </li>
          <li>
            <p>
              Provide at least as much padding around the logo as what we’ve
              displayed below.
            </p>
          </li>
        </ol>
        <p>
          Finally, if you have any questions about logo usage, you can reach us{' '}
          <a href="mailto:support@airpage.com">here</a>.
        </p>
      </div>
    </LegalContainer>
    <LegalContainerLight>
      <h1>AirPage Logos</h1>
            <Button
              as={'a'}
              href={'/logos.zip'}
              size="small"
              mr={['6']}
              display={'inline-flex'}
              target="_blank"
            >
              Download All Logos
            </Button>
      <h3>Black Square AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        <a href="/logos/svg/square/black.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/square/black.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>Color Square AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        <a href="/logos/svg/square/color.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/square/color.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>White Square AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center',
          background: '#01A1FF',
          padding: '10px'
        }}
      >
        <a href="/logos/svg/square/white.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/square/white.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>Black Horizontal AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        <a href="/logos/svg/horizontal/black.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/horizontal/black.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>Color Horizontal AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        <a href="/logos/svg/horizontal/color.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/horizontal/color.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>White Horizontal AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center',
          background: '#01A1FF',
          padding: '10px'
        }}
      >
        <a href="/logos/svg/horizontal/white.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/horizontal/white.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>Black Submark AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        <a href="/logos/svg/submark/black.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/submark/black.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>Color Submark AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center'
        }}
      >
        <a href="/logos/svg/submark/color.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/submark/color.svg"
            alt="airpage logo"
          />
        </a>
      </p>
      <h3>White Submark AirPage Logo</h3>
      <p
        style={{
          textAlign: 'center',
          background: '#01A1FF',
          padding: '10px'
        }}
      >
        <a href="/logos/svg/submark/white.svg" target="_blank">
          <img
            width="160px"
            src="/logos/svg/submark/white.svg"
            alt="airpage logo"
          />
        </a>
      </p>
    </LegalContainerLight>
  </MainLayout>
);
